import React, { useContext, useState } from 'react'
import logo from "../../../assets/logo-dark.svg"
import { Lock } from 'lucide-react'
import UserContext from '../../../contexts/UserContext';
import { UserApiService } from '../../../services/apiService';
import { toast } from 'react-hot-toast'
import { useNavigate } from 'react-router-dom';

const Login = () => {

    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const { getUserInfo } = useContext(UserContext);

    const handleSubmit = (e) => {
        e.preventDefault();
        let body = {
            email,
            password
        }

        UserApiService.adminLogin(body)
            .then(res => {
                if (res.data.success === true) {
                    let token = res.data.token;
                    localStorage.setItem('BCAdminToken', token);
                    getUserInfo(token)
                    toast.success("Logged in successfully")
                    navigate('/')
                } else {
                    toast.error(res.data.message)
                }
            })

    }

    return (
        <div className='bg-white py-10 min-h-screen'>
            <img
                src={logo}
                className='mx-auto'
            />
            <h2 className="mt-6 text-center text-3xl tracking-tight text-black">Sign in to your account</h2>
            <form onSubmit={handleSubmit} className="max-w-sm mx-auto mt-10">
                <div className="mb-5">
                    <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900">Your email</label>
                    <input
                        value={email}
                        onChange={(e) => { setEmail(e.target.value) }}
                        type="email"
                        id="email"
                        className="bg-gray-50 border-2 border-gray-300 focus:border-teal-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 focus:outline-none"
                        placeholder="user@example.com"
                        required
                    />
                </div>
                <div className="mb-5">
                    <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900">Your password</label>
                    <input
                        value={password}
                        onChange={(e) => { setPassword(e.target.value) }}
                        type="password"
                        id="password"
                        className="bg-gray-50 border-2 border-gray-300 focus:border-teal-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 focus:outline-none"
                        required
                    />
                </div>
                <button
                    type="submit"
                    className="flex items-center justify-center gap-2 text-white bg-teal-600 hover:bg-teal-700 focus:outline-none font-medium rounded-lg text-sm w-full px-5 py-2.5"
                >
                    <Lock size={16} /> Sign In
                </button>
            </form>
        </div>
    )
}

export default Login;