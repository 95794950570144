import React from 'react'
import { useState } from 'react';
import { BriefcaseBusiness, Eye, FileText, Grid2X2, Notebook, Shapes, Target, Users } from 'lucide-react';
import { useEffect } from 'react';
import { DashboardApiService } from '../../../services/apiService';

const Dashboard = () => {

    const [dashBoardData, setDashBoardData] = useState([]);


    useEffect(() => {
        DashboardApiService.getDashboardData()
            .then(res => {
                if (res.data.success === true) {
                    setDashBoardData(res.data.data);
                }
            })
            .catch(err => {
                console.log(err);
            })
    }, [])


    const TileComponent = ({ title, value, icon }) => {
        return (
            <div className="rounded-lg relative flex flex-col justify-between h-32 bg-teal-900 text-teal-100 text-left py-2 px-4 ">
                <div className="flex items-center justify-between px-2 h-4/5">
                    <div className="bg-teal-700 p-3 rounded-full">
                        {
                            icon === 'Users' ? <Users size={32} /> :
                                icon === 'Articles' ? <FileText size={32} /> :
                                    icon === 'Categories' ? <Grid2X2 size={32} /> :
                                        icon === 'SubCategories' ? <Shapes size={32} /> :
                                            icon === 'Glossary' ? <Notebook size={32} /> :
                                                icon === 'BrandLogos' ? <Target size={32} /> :
                                                    icon === 'DemoBookings' ? <BriefcaseBusiness size={32} /> :
                                                        icon === 'Views' ? <Eye size={32} /> : null
                        }
                    </div>
                    <p className="text-4xl font-semibold">{value}</p>
                </div>
                <label className="text-xl font-semibold">{title}</label>
            </div>
        )
    }

    return (
        <div className='p-5'>
            <div className='flex items-center justify-between' >
                <h1 className='text-2xl'>Dashboard</h1>
            </div>

            <div className='mt-10 grid grid-cols-3 gap-10' >
                {dashBoardData.map((data, index) => (
                    <TileComponent key={index} title={data.title} value={data.value} icon={data.icon} />
                ))}
            </div>
        </div>
    )
}

export default Dashboard;