import React, { useContext, useEffect } from 'react'
import UserContext from '../../contexts/UserContext';

const UserInfo = () => {

    const { getUserInfo } = useContext(UserContext)

    useEffect(() => {
        const token = localStorage.getItem('BCAdminToken');
        if (token) {
            getUserInfo(token)
        }
    }, [])
    return (
        <></>
    )
}

export default UserInfo;