import React, { useContext, useEffect, useState } from 'react'
import HeadlessUIModalComponent from '../../shared/HeadlessUIModal';
import { Plus, Trash2 } from 'lucide-react';
import UserContext from '../../../contexts/UserContext';
import { UserApiService } from '../../../services/apiService';
import { toast } from 'react-hot-toast';

const Users = () => {
    const { user } = useContext(UserContext)

    const [showAddAdmin, setShowAddAdmin] = useState(false)
    const [showDeleteAdmin, setShowDeleteAdmin] = useState(false)

    // Add admin states
    const [adminName, setAdminName] = useState("");
    const [adminEmail, setAdminEmail] = useState("");
    const [adminPassword, setAdminPassword] = useState("");

    // List
    const [adminList, setAdminList] = useState([]);

    // Delete admin states
    const [adminToDelete, setAdminToDelete] = useState(null);


    const getAdmins = () => {
        UserApiService.getAdminList({ token: user?.token })
            .then(res => {
                if (res.data.success === true) {
                    setAdminList(res.data.data)
                }
            }).catch(err => console.error(err))
    }

    useEffect(() => {
        getAdmins()
    }, [])


    const adminListJSX = (
        <div className="mt-6 flex flex-col">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div className="overflow-hidden border border-teal-50 md:rounded-lg">
                        <table className="min-w-full divide-y divide-teal-50">
                            <thead className="bg-teal-300">
                                <tr>
                                    <th
                                        scope="col"
                                        className="px-4 py-3.5 text-left text-sm font-normal text-gray-700"
                                    >
                                        Admin
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-12 py-3.5 text-left text-sm font-normal text-gray-700"
                                    >
                                        Email
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-4 py-3.5 text-center text-sm font-normal text-gray-700"
                                    >
                                        Actions
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-teal-50 bg-teal-300/50">
                                {adminList.map((admin) => (
                                    <tr key={admin.id}>
                                        <td className="whitespace-nowrap px-4 py-4">
                                            <div className="text-sm text-gray-600">{admin.name}</div>
                                        </td>
                                        <td className="whitespace-nowrap text-left px-12 py-4">
                                            <div className="text-sm text-gray-900">{admin.email}</div>
                                        </td>
                                        <td className="whitespace-nowrap text-center px-12 py-2">
                                            <button
                                                className="text-red-900 bg-red-200 hover:bg-red-300 p-2 rounded-lg focus:outline-none"
                                                onClick={() => {
                                                    setAdminToDelete(admin.id);
                                                    setShowDeleteAdmin(true);
                                                }}
                                            >
                                                <Trash2 size={18} />
                                            </button>

                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )

    const addAdminModalBodyJSX = (
        <div className='flex flex-col gap-4 my-5'>
            <div className='flex flex-col gap-2'>
                <label htmlFor="name" className="text-sm font-medium text-gray-700">Name</label>
                <input
                    type="text"
                    name="name"
                    id="name"
                    required
                    value={adminName}
                    onChange={(e) => setAdminName(e.target.value)}
                    className="block w-full px-3 py-2 border-2 border-gray-300 rounded-md shadow-sm focus:ring-teal-500 focus:border-teal-500 focus:outline-none sm:text-sm"
                />
            </div>
            <div className='flex flex-col gap-2'>
                <label htmlFor="email" className="text-sm font-medium text-gray-700">Email</label>
                <input
                    type="email"
                    name="email"
                    id="email"
                    required
                    value={adminEmail}
                    onChange={(e) => setAdminEmail(e.target.value)}
                    className="block w-full px-3 py-2 border-2 border-gray-300 rounded-md shadow-sm focus:ring-teal-500 focus:border-teal-500 focus:outline-none sm:text-sm"
                />
            </div>
            <div className='flex flex-col gap-2'>
                <label htmlFor="password" className="text-sm font-medium text-gray-700">Password</label>
                <input
                    type="password"
                    name="password"
                    id="password"
                    required
                    value={adminPassword}
                    onChange={(e) => setAdminPassword(e.target.value)}
                    className="block w-full px-3 py-2 border-2 border-gray-300 rounded-md shadow-sm focus:ring-teal-500 focus:border-teal-500 focus:outline-none sm:text-sm"
                />
            </div>
        </div >
    )

    const handleAddAdmin = (e) => {
        e.preventDefault();
        if (!adminName || !adminEmail || !adminPassword) {
            toast.error("All fields are required!")
            return
        }

        let body = {
            token: user?.token,
            name: adminName,
            email: adminEmail,
            password: adminPassword,
        }

        UserApiService.addAdmin(body)
            .then(res => {
                if (res.data.success === true) {
                    toast.success("Admin added successfully!")
                    getAdmins()
                    setShowAddAdmin(false)
                } else {
                    toast.error(res.data.message)
                }
            }).catch(err => {
                console.error(err)
                toast.error("Something went wrong!")
            })
    }

    const handleDeleteAdmin = () => {
        if (adminToDelete === null) {
            toast.error("No admin to delete!")
            return;
        }

        UserApiService.deleteAdmin({ token: user?.token, id: adminToDelete })
            .then(res => {
                if (res.data.success === true) {
                    toast.success("Admin deleted successfully.");
                    getAdmins();
                    setShowDeleteAdmin(false);
                    setAdminToDelete(null);
                }
            }).catch(err => {
                console.error(err);
                toast.error("Some error occurred!")
            })

    }


    return (
        <div className='p-5'>
            {/* Add admin */}
            <HeadlessUIModalComponent
                displayState={showAddAdmin}
                setDisplayState={setShowAddAdmin}
                headingChildren={"Add new admin"}
                bodyChildren={addAdminModalBodyJSX}
                footerChildren={
                    <div className='flex gap-2'>
                        <button
                            type="button"
                            className="inline-flex justify-center rounded-md border border-transparent bg-teal-600 px-4 py-2 text-sm font-medium text-teal-100 hover:bg-teal-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-teal-500 focus-visible:ring-offset-2"
                            onClick={handleAddAdmin}
                        >
                            Add Admin
                        </button>
                        <button
                            type="button"
                            className="inline-flex justify-center rounded-md border border-transparent bg-teal-200 px-4 py-2 text-sm font-medium text-teal-900 hover:bg-teal-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-teal-500 focus-visible:ring-offset-2"
                            onClick={() => { setShowAddAdmin(false) }}
                        >
                            Cancel
                        </button>
                    </div>
                }
            />

            {/* Delete admin */}
            <HeadlessUIModalComponent
                displayState={showDeleteAdmin}
                setDisplayState={setShowDeleteAdmin}
                headingChildren={"Delete admin"}
                bodyChildren={
                    <p className='my-5'>Do you really want to delete <span className='font-medium'>{adminList.find(ad => ad.id === adminToDelete)?.name || "this admin"}?</span></p>
                }
                footerChildren={
                    <div className='flex gap-2'>
                        <button
                            type="button"
                            className="inline-flex justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-red-100 hover:bg-red-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-teal-500 focus-visible:ring-offset-2"
                            onClick={handleDeleteAdmin}
                        >
                            Delete Admin
                        </button>
                        <button
                            type="button"
                            className="inline-flex justify-center rounded-md border border-transparent bg-red-200 px-4 py-2 text-sm font-medium text-red-900 hover:bg-red-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-teal-500 focus-visible:ring-offset-2"
                            onClick={() => { setShowDeleteAdmin(false); setAdminToDelete(null) }}
                        >
                            Cancel
                        </button>
                    </div>
                }
            />

            <div className='flex items-center justify-between' >
                <h1 className='text-2xl'>Admins</h1>
                <button
                    onClick={() => setShowAddAdmin(true)}
                    className='bg-teal-200 flex items-center gap-1 font-medium text-[15px] py-2 px-4 rounded-lg hover:bg-teal-300 text-teal-900 focus:outline-none'
                >
                    <Plus className='h-5 w-5' /> Add New Admin
                </button>
            </div>

            <div className='mt-5 w-10/12 mx-auto'>
                {adminListJSX}
            </div>
        </div>

    )
}

export default Users;